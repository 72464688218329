import http from '../../utils/http'

// 计划用电
export const apiWaterPlan = {
    // 列表
    list(params) {
        return http.request({
            url: '/cemp-energy/planned/water/plans',
            method: 'get',
            params
        })
    },
    // 初始化
    init(data) {
        return http.request({
            url: '/cemp-energy/planned/water/initialize',
            method: 'post',
            data
        })
    },
    // 编辑
    edit(data) {
        return http.request({
            url: '/cemp-energy/planned/water/change',
            method: 'put',
            data
        })
    },
    // 年配置
    info(params) {
        return http.request({
            url: '/cemp-energy/project-planned-range-water/year-info',
            method: 'get',
            params
        })
    },
    // 年配置-保存
    save_info(data) {
        return http.request({
            url: '/cemp-energy/project-planned-range-water/year-save',
            method: 'post',
            data
        })
    },
}